/* Custom styles to enhance Bootstrap */

/* Hover effects for cards */
.hover-shadow-lg {
  transition: all 0.3s ease;
}

.hover-shadow-lg:hover {
  transform: translateY(-5px);
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

/* Text hover effect */
.hover-text-white:hover {
  color: white !important;
  transition: color 0.3s ease;
}

/* Custom spacing classes */
.py-6 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

/* Object fit for images */
.object-fit-cover {
  object-fit: cover;
}

/* Transition effects */
.transition-all {
  transition: all 0.3s ease;
}

/* Animated Headlines */
.animated-headline-container {
  height: 40px; /* Set a fixed height to prevent layout shifts */
  margin-bottom: 1rem;
}

.animated-headline {
  transition: opacity 1s ease-in-out;
  opacity: 1;
}

.animated-headline.visible {
  opacity: 1;
}

.animated-headline.invisible {
  opacity: 0;
}

/* Custom ClinicPlus colors */
.bg-clinic-red {
  background-color: #dc3545;
}

.text-clinic-red {
  color: #dc3545;
}

.btn-clinic-red {
  background-color: #dc3545;
  border-color: #dc3545;
  color: white;
}

.btn-clinic-red:hover {
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-outline-clinic-red {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-clinic-red:hover {
  background-color: #dc3545;
  color: white;
}
