/* Custom styles for Services page */

/* Hover effects for cards */
.hover-shadow-lg {
  transition: all 0.3s ease;
}

.hover-shadow-lg:hover {
  transform: translateY(-5px);
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

/* Object fit for images */
.object-fit-cover {
  object-fit: cover;
}

/* Transition effects */
.transition-all {
  transition: all 0.3s ease;
}

/* Service navigation styling */
.service-nav .nav-link {
  color: #212529;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.service-nav .nav-link:hover {
  background-color: rgba(220, 53, 69, 0.1);
}

.service-nav .nav-link.active {
  background-color: #dc3545;
  color: white;
}

/* Accordion styling */
.accordion-button:not(.collapsed) {
  background-color: rgba(220, 53, 69, 0.1);
  color: #dc3545;
}

.accordion-button:focus {
  border-color: rgba(220, 53, 69, 0.5);
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

/* Badge styling */
.badge {
  font-weight: 500;
  letter-spacing: 0.5px;
}

/* Card icon styling */
.rounded-circle {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Link styling */
a {
  transition: all 0.3s ease;
}

a:hover {
  opacity: 0.8;
}
