/* Custom styles for About Us page */

/* Hover effects for cards */
.hover-shadow-lg {
  transition: all 0.3s ease;
}

.hover-shadow-lg:hover {
  transform: translateY(-5px);
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

/* Object fit for images */
.object-fit-cover {
  object-fit: cover;
}

/* Transition effects */
.transition-all {
  transition: all 0.3s ease;
}

/* Badge styling */
.badge {
  font-weight: 500;
  letter-spacing: 0.5px;
}

/* Card icon styling */
.rounded-circle {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Link styling */
a {
  transition: all 0.3s ease;
}

a:hover {
  opacity: 0.8;
}

.main-about {
  background-color: white;
}
